import { defineComponent } from 'vue';
import { Line } from 'vue-chartjs';
export default defineComponent({
  "extends": Line,
  props: {
    datasets: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    labels: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    title: {
      type: String,
      "default": function _default() {
        return '-';
      }
    },
    colors: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    showLabels: {
      type: Boolean,
      "default": false
    },
    yAxisTickCallback: {
      type: Function,
      "default": null
    },
    tooltipLabelCallback: {
      type: Function,
      "default": null
    }
  },
  watch: {
    datasets: function datasets() {
      this.updateChart();
    },
    yAxisTickCallback: function yAxisTickCallback() {
      this.updateChart();
    },
    tooltipLabelCallback: function tooltipLabelCallback() {
      this.updateChart();
    }
  },
  mounted: function mounted() {
    this.updateChart();
  },
  methods: {
    updateChart: function updateChart() {
      var options = {
        // legend: { display: false },
        responsive: true,
        maintainAspectRatio: false
      };

      if (this.yAxisTickCallback) {
        options.scales = {
          yAxes: [{
            ticks: {
              callback: this.yAxisTickCallback
            }
          }]
        };
      }

      if (this.tooltipLabelCallback) {
        options.tooltips = {
          callbacks: {
            label: this.tooltipLabelCallback
          }
        };
      } // @ts-ignore


      this.renderChart({
        labels: this.showLabels ? this.labels : [],
        datasets: this.datasets
      }, options);
    }
  }
});