import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
import { isNil, isNumber } from 'lodash';
import { defineComponent } from 'vue';
import { decimalFilter } from '../../../../common-ui';
export default defineComponent({
  props: {
    budget: {
      type: Number,
      "default": null
    },
    budgetRef: {
      type: Number,
      "default": null
    },
    betrag: {
      type: Number,
      "default": null
    },
    punkte: {
      type: Number,
      "default": null
    },
    punktwert: {
      type: Number,
      "default": null
    },
    cases: {
      type: Number,
      "default": null
    },
    property: {
      type: String,
      "default": 'euro'
    },
    light: {
      type: Boolean,
      "default": false
    },
    caseName: {
      type: Array,
      "default": ['-', '-']
    }
  },
  computed: {
    punktwertString: function punktwertString() {
      return isNil(this.punktwert) ? undefined : "Punktwert: ".concat(decimalFilter.filters.decimal(this.punktwert, 2), " \u20AC/P");
    }
  },
  methods: {
    logData: function logData() {
      console.log("hvmbayerncell: ".concat(this.budget, " ").concat(isNumber(this.budget), " ").concat(this.punkte, " ").concat(this.betrag, " ").concat(this.cases));
    }
  }
});