import { BehandlerService } from '@/services/behandlerService';
import { isNil } from 'lodash';

export const behandlerFilter = {
  filters: {
    behandler(id: string, field = 'displayText', addId = false, fallback = '?'): string {
      const bs = new BehandlerService();
      if (field === 'typ') {
        let b = bs.getBehandler(id);
        if (b && b.info) {
          return b.info.zahnarzt ? 'ZA' : b.info.pzr ? 'PZR' : fallback;
        }
        return fallback;
      }
      let s = bs.getProperty(id, field);
      if (addId) {
        s += ` (${id})`;
      }
      return isNil(s) ? fallback : s;
    },
  },
};
