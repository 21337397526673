import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
import { isNil, join } from 'lodash';
import { defineComponent } from 'vue';
import { decimalFilter } from '../../../../common-ui';
export default defineComponent({
  props: {
    main: {
      type: Number,
      "default": null
    },
    // referenz daten, um rotfärbung zu berechnen, fallback ist main
    mainRef: {
      type: Number,
      "default": null
    },
    faktor: {
      type: Number,
      "default": null
    },
    punkte: {
      type: Number,
      "default": null
    },
    punktwert: {
      type: Number,
      "default": null
    },
    betrag: {
      type: Number,
      "default": null
    },
    cases: {
      type: Number,
      "default": null
    },
    caseName: {
      type: Array,
      "default": function _default() {
        return ['-', '-'];
      }
    },
    property: {
      type: String,
      "default": 'punkte'
    },
    // make cell visually lighter
    light: {
      type: Boolean,
      "default": false
    },
    alwaysShowMain: {
      type: Boolean,
      "default": false
    }
  },
  data: function data() {
    return {
      showAltData: false
    };
  },
  computed: {
    betragOderPunkte: function betragOderPunkte() {
      return this.property === 'betrag' ? this.betrag : this.punkte;
    },
    punktwertString: function punktwertString() {
      return isNil(this.punktwert) ? undefined : "Punktwert: ".concat(decimalFilter.filters.decimal(this.punktwert, 4), " \u20AC/P");
    }
  },
  methods: {
    logData: function logData() {
      console.log("hvmcell, main:".concat(this.main, " (").concat(this.mainRef || '-', "), p:").concat(this.punkte, ", b:").concat(this.betrag, ", f:").concat(this.faktor, ", c:").concat(this.cases, ", cn:").concat(join(this.caseName), ", p:").concat(this.property, ", l:").concat(this.light));
    }
  }
});