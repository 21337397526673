import { defineComponent } from 'vue';
import HelpTooltip from '@/components/HelpTooltip.vue';
export default defineComponent({
  components: {
    HelpTooltip: HelpTooltip
  },
  props: {
    name: {
      type: String,
      "default": null
    },
    propname: String,
    sortfield: String,
    direction: String,
    help: String
  },
  methods: {
    sort: function sort() {
      console.log('sort', this.propname, this.sortfield, this.direction, this.sortfield === this.propname);
      var dir = 'desc';

      if (this.sortfield === this.propname) {
        switch (this.direction) {
          case 'asc':
            dir = undefined;
            break;

          case 'desc':
            dir = 'asc';
            break;

          case undefined:
            dir = 'desc';
            break;
        }
      }

      this.$emit('sort-changed', {
        field: this.propname,
        direction: dir
      });
    }
  }
});